/* Critical styles */
:root {
  --primary-color: #3B82F6;
  --secondary-color: #1E40AF;
  --text-color: #1F2937;
  --background-color: #FFFFFF;
}

/* Base styles */
body {
  margin: 0;
  font-family: 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Optimize paint and layout */
.animate-spin {
  will-change: transform;
}

/* Fonts loaded class */
.fonts-loaded body {
  opacity: 1;
  visibility: visible;
}

/* Critical layout classes */
.min-h-screen {
  min-height: 100vh;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.App {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #333;
}

.error-message {
  color: #dc3545;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #dc3545;
  border-radius: 4px;
  background-color: #f8d7da;
}

.filter-section {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.data-grid {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.data-grid th,
.data-grid td {
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  text-align: left;
}

.data-grid th {
  background-color: #f8f9fa;
  font-weight: 600;
}

.data-grid tr:nth-child(even) {
  background-color: #f8f9fa;
}

.data-grid tr:hover {
  background-color: #e9ecef;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.pagination button {
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.select-filter {
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  margin-right: 1rem;
  min-width: 200px;
}

.chart-container {
  margin: 2rem 0;
  height: 400px;
}

.recent-submissions {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.recent-submission-item {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}

.recent-submission-item:last-child {
  border-bottom: none;
}

.time-ago {
  color: #6c757d;
  font-size: 0.875rem;
}
